@import "./../../scss/global.scss";
@import "./../../scss/mixins.scss";

.breakfast-list-item {
  max-width: 100%;
  cursor: pointer;
  height: 100%;
  min-height: 200px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  padding: 1rem;
  background-color: $primary;
  max-height: 350px;
}

.breakfast-list-item-content {
  padding: 1rem;

  h3 {
    margin: 0 0 1rem 0;
  }

  p {
    margin: 0;
  }
}

.breakfast-list-item-price-pill {
  background: $white;
  color: $font;
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
}
.breakfast-list-item-name,
.breakfast-list-item-variant-name {
  pointer-events: none;
}

.breakfast-list-item-name {
  color: white;
  text-align: center;
  margin: 0;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.2em;
  margin-bottom: 0.5rem;
  letter-spacing: 0.48px;
}

.breakfast-list-item-variant-name {
  color: $secondary;
  font-size: 0.75rem;
  line-height: 1.2em;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
}

.breakfast-list-item-delete-button {
  color: $white;
  font-size: 2.5rem;
}

.breakfast-list-content {
  position: relative;
  z-index: 5;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.breakfast-list-checkbox {
  border-radius: 1rem;
  background-color: $primary;
  height: 30px;
  width: 60px;
  margin-bottom: 0.5rem;
  position: relative;
  cursor: pointer;
  display: flex;
  transition: 0.4s;

  & > .breakfast-checkbox-column {
    width: 50%;
    flex: 1 0 50%;
    max-width: 50%;
  }

  &.off {
    background-color: $secondary;
    transition: 0.4s;
    .breakfast-list-checkbox-dot {
      left: calc(95% - 25px);
    }
  }
}

.breakfast-list-checkbox-dot {
  position: absolute;
  top: 50%;
  left: 5%;
  width: 25px;
  height: 25px;
  background-color: $white;
  transform: translateY(-50%);
  border-radius: 12.5px;
  transition: 0.4s;
  z-index: 100;
}

.breakfast-checkbox-column {
  @include flex-both();

  color: $white;

  svg {
    transform: rotate(-45deg);
  }

  &.no-gluten {
    position: relative;

    &:before {
      content: '';
      height: 20px;
      width: 2px;
      position: absolute;
      top: 20%;
      left: 45%;
      background-color: $white;
      transform: rotate(-45deg);
    }
  }
}
