@import "./../../scss/variables.scss";

.adjust-day,
.adjust-hour {
  font-size: 0.875rem;
  line-height: 1.5rem;
  margin: 0;
  margin-bottom: 0.25rem;
}

.adjust-single-date {
  margin-bottom: 1rem;
}

.adjust-days-wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0.5rem;
}

@media (max-width: 680px) {
  .adjust-days-wrapper {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 576px) {
  .adjust-days-wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}

.adjust-days-screen {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  height: 100%;
  overflow: hidden;
  padding: 1rem;
  width: 100%;
}

.adjust-days-list {
  overflow: auto;
  height: 100%;
  padding-right: 1rem;
}
