@import "./../../scss/variables.scss";

.react-calendar {
  background-color: $gray;
  padding: 0.75rem;
  margin: 0 auto;
  max-width: 600px;
}

.react-calendar__navigation {
  display: flex;
  justify-content: space-between;
}

.react-calendar__navigation__arrow {
  border: none;
  background: none;
  -webkit-appearance: none;
  cursor: pointer;
}

.react-calendar__navigation__label__labelText {
  font-size: 1rem;
}

.react-calendar__navigation__label {
  pointer-events: none;
  -webkit-appearance: none;
  border: none;
  background: none;

  span {
    text-transform: uppercase;
    color: $calendar-text;
  }
}

.react-calendar__month-view__weekdays__weekday {
  font-size: 0.75rem;
  line-height: 1.2em;
  color: $calendar-text;
  text-align: center;
  margin: 0.5rem 0;

  abbr {
    text-transform: capitalize;
    text-decoration: none;
    font-weight: 700;
  }
}

.react-calendar__tile {
  -webkit-appearance: none;
  background-color: none;
  border: none;
  background: none !important;
  background-color: $white !important;
  color: $primary;
  border-radius: 0.25rem;
  aspect-ratio: 1;
  cursor: pointer;

  &:disabled {
    background-color: $secondary !important;
    color: $white;
  }
}

.react-calendar__tile {
  &.react_calendar__sunday:disabled {
    background-color: $red !important;
    color: $white;
  }
}

.react-calendar__month-view__days {
  display: grid !important;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 0.5rem;
}

.react-calendar__tile--now {
  border: 3px solid $green;
  background: none !important;
  color: $primary !important;

  &.react-calendar__tile--active-state {
    color: $white !important;
  }
}

.react-calendar__tile--active-state {
  background: $primary !important;
  color: $white;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: $gray;

  &:disabled {
    background-color: $secondary !important;
  }
}
