@import "./../../scss/mixins.scss";
.schedule-screen {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  overflow: auto;
  padding: 1rem;
  width: 100%;
  align-items: center;

  & > * {
    width: 100%;
  }
}

.schedule-screen-top {
  margin-bottom: 2rem;
}

.schedule-title {
   margin-bottom: 1rem;
}