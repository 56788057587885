@import './../../scss/variables.scss';

.adjust-screen-tile {
    padding: .5rem;
    color: $white;
    display: flex;
    flex-direction: column;
    position: relative;

    & > * {
        margin: 0;
    }
}

.adjust-screen-tile-breakfast-name {
    font-size: .75rem;
    font-weight: 400;
    line-height: 1.2em;
    margin-bottom: .25rem;
}

.adjust-screen-tile-variant-name {
    margin-bottom: .5rem;
}

.adjust-screen-tile-variant-name, 
.adjust-screen-tile-variant-price {
    text-transform: uppercase;
    font-size: .7rem;
    font-weight: 100;
}

.adjust-screen-tile-variant-price {
    font-weight: 600;
}

.currency {
    text-transform: none;
}

.adjust-screen-tile-quantity {
    position: absolute;
    background: $white;
    border: 1px solid $gray;
    border-radius: .25rem;
    bottom: -3%;
    right: 5%;
    opacity: 1 !important;

    &:disabled {
        opacity: 1 !important;
        -webkit-appearance: none;
        min-width: 33px;
        text-align: center;
    }
}