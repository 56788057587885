@import "./../../scss/variables.scss";
@import "./../../scss/mixins.scss";

.next-step-wrapper {
  background-color: $gray;
  padding: 1rem;
  width: 100%;
  display: flex;
  flex-direction: row;


  .price-header {
    margin: 0;
    margin-bottom: 1rem;
    font-size: 1rem;
    letter-spacing: 0.48px;
    line-height: 1.2em;
  }

  .price {
    color: $primary;
  }

  .next-step-buttons {
    width: 100%;
  }

  button.button.big {
    height: 50px;
    font-size: 1rem;
    font-weight: bold;
    margin-left: auto;
    max-width: 240px;
  }
}