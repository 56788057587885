@import './../../scss/mixins';
@import './../../scss/variables.scss';

.loader {
  width: 100%;
  height: 100%;
  flex-direction: column;
  background: $primary;
  
  @include flex-both();
  
  p {
    color: $white;
  }
}

.spinner {
  animation: spin infinite 5s linear;
  font-size: 1.5rem;
  color: $white;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
