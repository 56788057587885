@import "./../../scss/variables.scss";

.contact-wrapper {
    padding: .25rem;
}

.contact-column {
    svg {
        margin-right: .5rem;
    }
    font-size: .75rem;
}

.order-status-order-content {
    overflow: auto;
}

.order-status-screen {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    max-height: 100%;
}