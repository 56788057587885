@import "./../../scss/variables.scss";

.breakfasts-screen-grid {
  display: grid;
  gap: 1rem;
  height: 100%;
  padding: 0 2rem;
  grid-template-columns: repeat(2, 1fr);
}


@media (max-width: 320px) {
  .breakfasts-screen-grid {
    grid-template-columns: 1fr;
  }
}

.title,
.subtitle {
  line-height: 1.2em;
  text-align: center;
}

.title {
  font-size: 1rem;
  font-weight: bold;
  margin: 0;
  letter-spacing: 0.48px;
  text-transform: uppercase;
  margin-bottom: 1rem;
}

.subtitle {
  color: $font;
  font-size: 0.875rem;
  margin-bottom: 1rem;
}

.subtitle-muted {
  color: $secondary;
  font-size: 0.6rem;
  line-height: 1.2em;
  text-align: center;
  margin-bottom: 1rem;
}

.breakfasts-screen-wrapper {
  overflow: auto;
  width: 100%;
}
