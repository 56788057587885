@import "./../../scss/mixins.scss";
@import "./../../scss/variables.scss";

.step-counter {
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 1.5rem 1rem 0;
  margin-top: 1rem;
  max-width: 768px;
  width: 100%;

  .step {
    flex-direction: column;
    position: relative;
    display: flex;
    align-items: center;
    max-width: 40px;
    font-weight: 700;

    &.step-current {
      .step-step {
        background-color: $primary;
        color: $white;
      }

      span {
        color: $primary;
      }
    }

    &.step-completed {
      .step-step {
        background-color: $font;
        color: $white;
      }

      span {
        color: $font;
      }
    }
  }

  span {
    font-size: 0.75rem;
    width: 90px;
    display: block;
    text-align: center;
    line-height: 1.05;
    margin-bottom: 0.25rem;
    overflow: visible;
    display: flex;
    justify-content: center;
    color: $secondary;
    position: absolute;
    bottom: 105%;
    font-weight: 400;

  }

  .step-step {
    @include flex-both();
    background-color: $secondary;
    aspect-ratio: 1;
    width: 40px;
  }

  .arrow {
    color: $secondary;
    font-size: 1.25rem;

    &.step-current,
    &.step-completed {
      color: $primary;
    }

    &.step-completed {
      color: $font;
    }
  }
}
