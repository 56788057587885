@import "./../../scss/variables.scss";
@import "./../../scss/mixins.scss";

@keyframes animate {
  0%{
    transform: scale(1);
  }30% {
    transform: scale(1.1) rotate(-10deg);
  }100% {
    transform: scale(1) rotate(0);
  }
}

.cart-widget {
  background-color: $green;
  width: 70px;
  height: 70px;
  position: fixed;
  bottom: 1rem;
  left: 1rem;
  border-radius: 35px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  @include flex-both();
  z-index: 10;

  svg {
    color: $white;
    font-size: 2rem;
    margin-top: 0.25rem;
  }

  &.animate {
    animation: animate .3s linear;
  }

  &-counter {
    background-color: $primary;
    width: 25px;
    height: 25px;
    border-radius: 12.5px;
    font-size: 0.75rem;
    color: white;
    position: absolute;
    top: 17%;
    right: 17%;

    @include flex-both();
  }
}
