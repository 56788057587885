@import './../../scss/variables.scss';
@import './../../scss/mixins.scss';

.breakfast-screen {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.breakfast-image-wrapper {
    text-align: center;
    min-height: 30%;
    background: $primary;
    color: $white;
    overflow: hidden;
    position: relative;

    @include flex-both();
    flex-direction: column;

    background-size: cover;
    background-position: center;

    h3, h5 {
        margin: 0;
    }

    h3 {
        font-size: 1.5rem;
        letter-spacing: .72px;
        line-height: 1.2em;
        font-weight: 600;
        margin-bottom: .5rem;
    }

    h5 {
        color: $secondary;
        font-size: .75rem;
        line-height: 1.2em;
        text-transform: uppercase;
    }

}


.breakfast-content-wrapper {
    padding: 1.5rem 2rem;
    max-height: 100%;
    height: 100%;
    overflow: auto;

    h3 {
        margin: 0;
        margin-bottom: .5rem;
    }
}

@media (max-width: 320px) {
    .breakfast-content-wrapper {
        padding: 1rem;
    }
}

.breakfast-content-tag-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: .25rem;
    margin-left: 1rem;
}

.breakfast-content-tag, .breakfast-content-variant {
    padding: .25rem;
    text-transform: uppercase;
    text-align: center;
}

.breakfast-content-tag {
    background: $calendar-text;
    color: white;
    font-size: .5rem;
    border-radius: 3px;
    padding: .25rem .75rem;
}

.breakfast-content-variant {
    color: $calendar-text;
    border: 1px solid $calendar-text;
    cursor: pointer;
    font-size: .6rem;
    padding: .25rem .75rem;
    border-width: 2px;
    border-radius: .25rem;
    font-weight: bold;
    font-size: .75rem;

    &.active {
        border-color: $primary;
        color: $primary;
    }
}

.breakfast-content-title-wrapper {
    display: flex;
    justify-content: space-between;
}

.breakfast-content-price{
    font-size: 1.5rem;
    font-weight: 700;
    color: $primary;
}

.breakfast-variants-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: .5rem;
    margin-bottom: 1rem;
    margin-top: 1.5rem;
}

.breakfast-content-additional-info {
    display: flex;
    align-items: center;
    margin-bottom: .5rem;
    gap: .5rem;

    p {
        margin: 0;
        font-size: .75rem;
    }
}

.breakfast-image-close-wrapper {
    position: absolute;
    top: 30px;
    right: 30px;
    z-index: 2;
    cursor: pointer;

    svg {
        font-size: 2rem;
    }
}

.breakfast-content-description {

    ol, ul {
        margin: 0;
        padding: 0 1.5rem;
    }

    li {
        font-size: .85rem;
        line-height: 1.2em;
    }
}

.breakfast-content-header {
    font-size: .9rem;
    margin: 0;
}