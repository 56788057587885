@import url("https://use.typekit.net/xxo0xhx.css");

@import "./variables.scss";
@import "./mixins.scss";

body {
  margin: 0;
  padding: 0;
  font-family: "aller";
  height: 100dvh;
}

* {
  box-sizing: border-box;
  font-family: "Aller";
}

#root {
  margin: 0 auto;
  background: $white;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  justify-content: space-between;
}

p {
  margin: 0;
  margin-bottom: 0.5rem;
}

.flex {
  display: flex;
}

.center {
  justify-content: center;
}

.between {
  justify-content: space-between;
}

.align-center {
  align-items: center;
}

.end {
  justify-content: flex-end;
}

.button {
  padding: 0.5rem 1rem;
  border: 2px solid;
  font-size: 0.75rem;
  cursor: pointer;
  width: 100%;
  -webkit-appearance: none;
  transition: 0.4s;
  max-width: 500px;
  text-decoration: none;
  text-align: center;

  &:disabled {
    opacity: 0.7;
  }

  &.white {
    color: $font;
    background-color: $white;
    border-color: $white;

    &:hover {
      background-color: $gray;
      border-color: $gray;
      color: $font;
      transition: 0.4s;
    }
  }

  &.primary {
    color: $white;
    background-color: $primary;
    border-color: $primary;
  }

  &.big {
    padding: 0.75rem 0.25rem;
    font-size: 0.8rem;
    height: 50px;
    font-weight: bold;
  }

  &.outline-primary {
    border-color: $primary;
    color: $primary;
    background-color: $white;
  }

  &.outline-secondary {
    border-color: $secondary;
    color: $secondary;
    background-color: $white;

    &.active {
      border-color: $primary;
      color: $primary;
    }
  }
}

a.button {
  display: flex;
}

.layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  max-width: 100%;
  overflow: hidden;
  width: 100%;
  flex-grow: 1;
}

.padded-list {
  padding: 0 1rem;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.bg-primary {
  background-color: $primary;
}

.bg-secondary {
  background-color: $secondary;
}

.color-white {
  color: white;
}

.card {
  padding: 1rem;
  background-color: $primary;
  width: 100%;
  color: $white;
  margin-bottom: 1rem;

  @include flex-both();
}

.gap {
  gap: 1rem;
}

.label {
  display: flex;
  margin-bottom: 1rem;
  position: relative;
  cursor: pointer;
  align-items: center;
  
  .icon {
    color: $primary;
    position: absolute;
    top: 50%;
    left: 3px;
    transform: translateY(-50%);
    font-size: 1.5rem;
    display: none;
  }
}

@media (max-width: 380px) {
  .label.same-everyday {
    font-size: .9rem;
  }
}

@media (max-width: 350px) {
  .label.same-everyday {
    font-size: .75rem;
  }
}

.checkbox {
  width: 25px;
  height: 25px;
  border: 1px solid $secondary;
  -webkit-appearance: none;
  margin: 0;
  margin-right: 0.5rem;
  cursor: pointer;

  &:checked ~ .icon {
    display: block;
  }
}

.summary-screen {
  padding: 2rem;
  width: 100%;
  overflow: hidden;
}

.screen-404 {
  width: 100%;
  text-align: center;
  height: 100%;
}

.paid {
  color: $green;
}

.not-paid {
  color: $red;
}

.margin-1 {
  margin-bottom: 1rem;
}

a {
  color: $font;
  text-decoration: none;
}

.Toastify {
  position: absolute;
}
