@import "./../../scss/variables.scss";
@import "./../../scss/mixins.scss";

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  @include flex-both();
}

.popup-content {
  background-color: $white;
  padding: 2rem;
  max-width: 80%;
  width: 100%;
  border-radius: 0.1rem;
  position: relative;
}

.popup-close-icon {
  position: absolute;
  top: -60px;
  right: 0;
  color: $white;
  font-size: 2rem;
  cursor: pointer;
}
